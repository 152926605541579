var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Details")]),
      _c(
        "gov-table",
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Name of " + _vm._s(_vm.service.type))
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.service.name))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Organisation for " + _vm._s(_vm.service.type))
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.service.organisation.name))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v(
                      _vm._s(_vm._f("ucfirst")(_vm.service.type)) +
                        " website address"
                    )
                  ]),
                  _c("gov-table-cell", { attrs: { break: "" } }, [
                    _vm._v(_vm._s(_vm.service.url))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v(
                      _vm._s(_vm._f("ucfirst")(_vm.service.type)) + " logo"
                    )
                  ]),
                  _c(
                    "gov-table-cell",
                    [
                      _vm.service.image
                        ? _c("ck-image", {
                            attrs: { "file-id": _vm.service.image.id }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Summary of " + _vm._s(_vm.service.type))
                  ]),
                  _c("gov-table-cell", [_vm._v(_vm._s(_vm.service.intro))])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v(
                      _vm._s(_vm._f("ucfirst")(_vm.service.type)) +
                        " description"
                    )
                  ]),
                  _c("gov-table-cell", {
                    domProps: {
                      innerHTML: _vm._s(_vm.toHtml(_vm.service.description))
                    }
                  })
                ],
                1
              ),
              _vm.appServiceOfferingsActive
                ? _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Offerings")]
                      ),
                      _c(
                        "gov-table-cell",
                        [
                          _vm.service.offerings.length > 0
                            ? _c(
                                "gov-list",
                                { attrs: { bullet: "" } },
                                _vm._l(_vm.service.offerings, function(ref) {
                                  var offering = ref.offering
                                  var order = ref.order
                                  return _c(
                                    "li",
                                    { key: "ServiceOffering::" + order },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(offering) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : [_vm._v("None")]
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Is a national service")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm.service.national ? "Yes" : "No"))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Attending type")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(
                      _vm._s(
                        _vm.service.attending_type
                          ? _vm.service.attending_type.charAt(0).toUpperCase() +
                              _vm.service.attending_type
                                .replaceAll("_", " ")
                                .slice(1)
                          : ""
                      )
                    )
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Attending access")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(
                      _vm._s(
                        _vm.service.attending_access
                          ? _vm.service.attending_access
                              .charAt(0)
                              .toUpperCase() +
                              _vm.service.attending_access
                                .replaceAll("_", " ")
                                .slice(1)
                          : ""
                      )
                    )
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Status")
                  ]),
                  _c("gov-table-cell", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.service.status === "active" ? "Enabled" : "Disabled"
                      )
                    }
                  })
                ],
                1
              ),
              _vm.auth.isSuperAdmin
                ? _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Quality Score")]
                      ),
                      _c("gov-table-cell", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.qualityScores[_vm.service.score]
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("End date")
                  ]),
                  _c("gov-table-cell", [
                    _vm._v(_vm._s(_vm._f("endsAt")(_vm.service.ends_at)))
                  ])
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v("Last updated")
                  ]),
                  _c(
                    "gov-table-cell",
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("lastModifiedAt")(
                              _vm.service.last_modified_at
                            )
                          ) +
                          "\n          "
                      ),
                      _vm.auth.isServiceAdmin(_vm.service.id)
                        ? [
                            !_vm.refreshForm.$submitting
                              ? _c(
                                  "gov-link",
                                  { on: { click: _vm.onMarkAsStillUpToDate } },
                                  [_vm._v("(mark as still up to date)")]
                                )
                              : [_vm._v("(marking as still up to date...)")]
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "gov-table-row",
                [
                  _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                    _vm._v(
                      "Gallery items (" +
                        _vm._s(_vm.service.gallery_items.length) +
                        ")"
                    )
                  ]),
                  _c(
                    "gov-table-cell",
                    { staticStyle: { width: "50%" } },
                    [
                      _vm.service.gallery_items.length
                        ? _c("ck-carousel", {
                            attrs: {
                              "image-ids": _vm.service.gallery_items.map(
                                function(galleryItem) {
                                  return galleryItem.file_id
                                }
                              )
                            }
                          })
                        : _c("gov-body", [_vm._v("-")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.appServiceTagsActive
                ? _c(
                    "gov-table-row",
                    [
                      _c(
                        "gov-table-header",
                        { attrs: { top: "", scope: "row" } },
                        [_vm._v("Tags")]
                      ),
                      _c(
                        "gov-table-cell",
                        [
                          _vm.service.tags.length > 0
                            ? _c(
                                "gov-list",
                                { attrs: { bullet: "" } },
                                _vm._l(_vm.service.tags, function(tag, index) {
                                  return _c(
                                    "li",
                                    { key: "ServiceTag::" + index },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(tag.label) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : [_vm._v("None")]
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }